<template>
  <van-pull-refresh v-model="reFreshLoading" @refresh="onRefresh" style="height: 100vh; overflow: scroll">
    <div class="padding15">
      <div class="myinfo-box">
        <base-avatar :src="userInfo.header" height="60"></base-avatar>
        <div class="info">
          <div class="name">
            {{ userInfo.memberName }}
            <span v-if="userInfo.isDoctor">{{ userInfo.doctorTitle }}</span>
          </div>
          <div v-if="userInfo.isDoctor" class="tip">{{ userInfo.hospitalName }} {{ userInfo.department }}</div>
          <div v-if="!userInfo.isDoctor" class="tip">{{ userInfo.phone }}</div>
        </div>
        <div class="button" @click="handleNavigation('userInfo')">
          个人资料
          <van-icon name="arrow" size="9" color="#5E7DFF" />
        </div>
      </div>
      <base-box
        padding="4px"
        :style="{
          background: 'linear-gradient(135deg, #90AAFE, #5E7DFF)',
          boxShadow: '0px 3px 10px 0px rgba(95, 126, 255, 0.5)',
          paddingBottom: '15px',
        }"
        v-if="!userInfo.isDoctor"
      >
        <van-grid :border="false">
          <van-grid-item
            v-for="(item, index) in seekList"
            :key="index"
            :to="item.title == '问诊订单' ? '/patientOrder/all' : item.to"
            :dot="
              item.title == '待支付' && myData.noPayCount > 0
                ? true
                : item.title == '待接诊' && myData.daiJieZhenCount > 0
                ? true
                : item.title == '进行中' && myData.daiHuiFuCount > 0
                ? true
                : false
            "
          >
            <template #icon>
              <van-image :src="item.img" width="25" height="25" />
            </template>
            <template #text>
              <div class="grid-title white">
                {{ item.title }}
              </div>
            </template>
          </van-grid-item>
        </van-grid>
      </base-box>

      <base-box padding="4px" v-if="!userInfo.isDoctor">
        <div class="my-title">
          <div class="title">我的服务</div>
          <div class="info" @click.stop="handleNavigation('messageList')">
            系统消息
            <span>
              [<span :style="{ color: myData.noReadMessage > 0 ? '#FF5E5E' : '' }">{{ myData.noReadMessage }}</span
              >未读]
            </span>
          </div>
        </div>
        <van-grid :border="false">
          <van-grid-item v-for="(item, index) in gridList" :key="index" :to="item.to">
            <van-image :src="item.img" width="30" height="30" />
            <div class="grid-title">
              {{ item.title }}
            </div>
          </van-grid-item>
        </van-grid>
      </base-box>
      <base-box padding="4px" :style="{ marginBottom: '10px' }">
        <van-cell title="医生注册" is-link :title-style="{ flex: 2 }" :to="`/doctorApply2/${userInfo.rzState}`">
          <template #title>
            医生注册
            <span style="color: #ff5e5e; font-size: 12px">仅供医生注册</span>
          </template>
          <template #icon>
            <div class="my-icon-list">
              <van-icon :name="require('../../assets/ico-apply.png')" class="search-icon" size="20" />
            </div>
          </template>
          <template #value>
            <span v-if="userInfo.rzState == -1">未认证</span>
            <span v-if="userInfo.rzState == 0">认证中</span>
            <span v-if="userInfo.rzState == 1">未通过</span>
            <span v-if="userInfo.rzState == 2" style="color: #ff5e5e">{{
              isApplyComplete ? '已认证' : '请补全认证信息'
            }}</span>
          </template>
        </van-cell>
        <van-cell title="我的钱包" is-link to="/wallet" value-class="wallet-num" v-if="userInfo.isDoctor">
          <template #icon>
            <div class="my-icon-list">
              <van-icon :name="require('../../assets/ico-wallet.png')" class="search-icon" size="20" />
            </div>
          </template>
          <template #value v-if="myData.balance"> ¥ {{ myData.balance }} </template>
        </van-cell>
        <!-- <van-cell title="扫码关注" is-link to="/qrCode" v-if="!userInfo.isDoctor">
          <template #icon>
            <div class="my-icon-list">
              <van-icon :name="require('../../assets/ico-qrcode.png')" class="search-icon" size="20" />
            </div>
          </template>
        </van-cell> -->

        <van-cell title="我的推广" is-link v-if="userInfo.isDoctor" to="/extension">
          <template #icon>
            <div class="my-icon-list">
              <van-icon :name="require('../../assets/ico-ad.png')" class="search-icon" size="20" />
            </div>
          </template>
        </van-cell>
        <van-cell title="常见问题" is-link to="/document/常见问题">
          <template #icon>
            <div class="my-icon-list">
              <van-icon :name="require('../../assets/ico-question.png')" class="search-icon" size="20" />
            </div>
          </template>
        </van-cell>

        <van-cell title="用户协议" is-link to="/document/用户协议">
          <template #icon>
            <div class="my-icon-list">
              <van-icon :name="require('../../assets/ico-agreement.png')" class="search-icon" size="20" />
            </div>
          </template>
        </van-cell>
        <van-cell title="隐私条款" is-link to="/document/隐私条款">
          <template #icon>
            <div class="my-icon-list">
              <van-icon :name="require('../../assets/ico-agreement.png')" class="search-icon" size="20" />
            </div>
          </template>
        </van-cell>
        <van-cell title="意见反馈" is-link to="/wallet" v-if="false">
          <template #icon>
            <div class="my-icon-list">
              <van-icon :name="require('../../assets/ico-listen.png')" class="search-icon" size="20" />
            </div>
          </template>
        </van-cell>
      </base-box>
      <van-button round color="#FFFFFF" class="quit-button" style="color: #ff5e5e" @click="handleOut">
        退出登录
      </van-button>
    </div>
  </van-pull-refresh>
</template>

<script>
import BaseBox from '@/components/BaseBox.vue';
import { Button, Cell, Grid, GridItem, Icon, Image, PullRefresh, Toast } from 'vant';
import { myGridList, mySeekList, doctorApplyData } from '@/utils/data.js';
import { getStorage } from '@/utils/storage.js';
import store from '@/store';
import BaseAvatar from '@/components/BaseAvatar.vue';
import { useRouter } from 'vue-router';
import { getPersonalCenter } from '@/api/home';
import { loginOut } from '@/api/login';
import { ref } from '@vue/reactivity';
import { onActivated } from '@vue/runtime-core';

export default {
  name: 'my',
  components: {
    BaseBox,
    [Cell.name]: Cell,
    BaseAvatar,
    [Icon.name]: Icon,
    [Button.name]: Button,
    [Grid.name]: Grid,
    [GridItem.name]: GridItem,
    [Image.name]: Image,
    [PullRefresh.name]: PullRefresh,
  },
  setup() {
    const userInfo = ref(store.getters['user/userInfo']);
    const gridList = myGridList.filter((v) => v.isDoctor == userInfo.value.isDoctor || v.isDoctor == undefined);

    const isApplyComplete = ref(true);
    onActivated(() => {
      if (userInfo.rzState == 2) {
        Object.keys(doctorApplyData).forEach((v) => {
          if (!JSON.parse(getStorage('doctorApply'))[v]) {
            isApplyComplete.value = false;
          }
        });
      }
    });
    const seekList = mySeekList.filter((v) => v.isDoctor == userInfo.value.isDoctor || v.isDoctor == undefined);

    const router = useRouter();
    const handleNavigation = function (name) {
      router.push({ name });
    };

    const myData = ref({});
    const getMypageInfo = function () {
      getPersonalCenter().then((res) => {
        myData.value = res.data;
        userInfo.value = res.data.userInfo;
        store.commit('user/setUserInfo', res.data.userInfo);
        reFreshLoading.value = false;
      });
    };
    // getMypageInfo();

    onActivated(() => {
      getMypageInfo();
    });
    const reFreshLoading = ref(false);
    const onRefresh = function () {
      reFreshLoading.value = true;
      getMypageInfo();
    };

    const handleOut = async function () {
      const { code } = await loginOut();
      if (code == 200) {
        Toast('退出成功');
        window.localStorage.clear();
        store
          .dispatch('user/resetAll')
          .then(() => router.push({ path: '/login', replace: true, query: { isCode: false } }).then(() => {}));
      }
    };
    return {
      gridList,
      seekList,
      userInfo,
      myData,
      handleNavigation,
      onRefresh,
      reFreshLoading,
      isApplyComplete,
      handleOut,
    };
  },
};
</script>

<style lang="scss">
.my-icon-list {
  img {
    margin-right: 20px;
  }
}
.wallet-num {
  font-size: $font18;
  color: black;
  font-weight: 600;
}

.my-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: $black;
  padding-left: 20px;
  padding-top: 20px;
  padding-right: 10px;

  .title {
    font-size: $font15;
  }
  .info {
    font-size: $font12;
    span {
      color: #a6aeb3;
    }
  }
}
.quit-button {
  width: 100%;
  margin-top: 15px;
  border-radius: 10px;
  margin-bottom: 80px;
}
</style>
