<template>
  <van-image :width="height" :height="height" round fit="cover" :src="src" />
</template>

<script>
import { Image } from 'vant';

export default {
  name: 'BaseAvatar',
  inheritAttrs: false,
  components: {
    [Image.name]: Image,
  },
  props: {
    height: {
      type: String,
      default: '40',
    },
    src: {
      type: String,
      default: '',
      required: true,
    },
  },
};
</script>
