<template>
  <div class="base-box" :style="{ padding }">
    <slot />
  </div>
</template>

<script>
export default {
  props: {
    padding: {
      type: [String, Number],
    },
  },
};
</script>

<style lang="scss" scoped>
.base-box {
  background: #ffffff;
  box-shadow: 0px 3px 20px 0px rgba(0, 0, 0, 0.06);
  border-radius: 10px;
  padding: 20px;
  margin-bottom: 15px;
  margin-top: 15px;
  overflow: hidden;
}
</style>
