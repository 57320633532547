import request from '@/utils/request';


/**
 * @description 个人中心--我的
 * @returns
 */
export function getPersonalCenter(params) {
  return request({
    url: '/member/getPersonalCenter',
    method: 'get',
    params,
  });
}

export function getIconList(params) {
  return request({
    url: '/getIconList',
    method: 'get',
    params,
  });
}


export function getScienceList(params) {
  return request({
    url: '/getScienceList',
    method: 'get',
    params,
  });
}

/**
 * @description  科普详情
 * @param {id} params
 * @returns
 */
export function getScienceModel(params) {
  return request({
    url: '/getScienceModel',
    method: 'get',
    params,
  });
}
