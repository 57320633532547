import request from '@/utils/request';


/**
 * @description
 * @param {code} params
 * @returns
 */
export function doLogin(data) {
  return request({
    url: '/authorization/doLogin',
    method: 'post',
    data,
  });
}

/**
 * @description
 * @param {code} params
 * @returns
 */
export function loginOut(data) {
  return request({
    url: '/member/out',
    method: 'post',
    data,
  });
}

/**
 * @description openid登录
 * @param {openId} params
 * @returns
 */

export function wxMpLoginOpenId(data) {
  return request({
    url: '/authorization/wxMpLoginOpenId',
    method: 'post',
    data,
  });
}

/**
 * @description 获取签名
 * @param {url} params
 * @returns
 */

export function getShare(params) {
  return request({
    url: '/authorization/getShare',
    method: 'get',
    params,
  });
}
